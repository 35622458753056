import { Box, Button, Card, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatLocaleDate } from 'utils/dateFormat';
import { BackButton } from './BackButton';
import { linkHelper } from 'linkHelper';
import { Gravatar } from '../base/Gravatar';
import { useGetUser } from 'api/users';
import { useDeleteUser, useReinviteUser, useUserChangeTwoAuth, User, useGetUsers } from 'api/users';
import { useUserResendAllSitesPendingInvite } from 'api/auth';
import { useDeleteSiteUserForAllSites } from 'api/site';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { IconWrapper } from '../base/IconWrapper';
import { useEffect, useState } from 'react';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import UserPersonalInfo from './UserPersonalInfo';
import { UserTwoFactorAuth } from './UserTwoFactorAuth';

// icons
import RefreshIcon from '../icons/Refresh.svg?react';
import EnvelopeIcon from '../icons/Envelope.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import LockClosed from '../icons/LockClose.svg?react';

export default function UserProfile({ userType }: { readonly userType: User['type'] }) {
  const { t, i18n } = useTranslation();
  const { userId = '' } = useParams<{ userId: string }>();
  const { data, isLoading } = useGetUser(userId);
  const [userDeleting, setUserDeleting] = useState<boolean>(false);
  const { mutateAsync: deleteAccountUser } = useDeleteUser();
  const { mutateAsync: deleteSiteUser } = useDeleteSiteUserForAllSites();
  const { mutateAsync: reinviteAccountUser } = useReinviteUser();
  const { mutateAsync: resendInvite } = useUserResendAllSitesPendingInvite();
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth();
  const user = data?.result ?? null;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmDelete = async () => {
    if (!userDeleting || !user?.id) {
      throw new Error('User ID is missing');
    }

    if (user.type === 'account') {
      await deleteAccountUser({ userId: user.id });
    } else {
      // need to figure this out since site users take numbers for ids, also we need an api to delete all the site associations
      await deleteSiteUser({ userId: user.id });
    }
    enqueueSnackbar(t('user_removed_successfully'), {
      key: 'RemoveUser',
      variant: 'success',
    });
    setUserDeleting(false);
    navigate(linkHelper.newDesign.settings.users.getLink());
  };

  useEffect(() => {
    if (user?.type && user.type !== userType) {
      navigate(linkHelper.newDesign.settings.users[user.type].profile.getLink({ userId }));
    }
  }, [user?.status]);

  const actions = [
    {
      onClick: async () => {
        // TODO - need the text for the snackbar
        user && (await changeTwoFactorAuth({ userId: user?.id ?? '', twofa: true }));
      },
      icon: <LockClosed />,
      label: t('force_2factor_auth'),
      hidden: () => userType === 'account' && user?.twofactor_required === false,
    },
    {
      onClick: async () => {
        if (!user) {
          return;
        }
        if (user.type === 'account') {
          await reinviteAccountUser({ userId: user.id ?? '' });
        } else {
          await resendInvite({
            userId: user.id ?? '',
          });
        }
        enqueueSnackbar(t('invite_resent_successfully'), {
          key: 'InviteUser',
          variant: 'success',
        });
      },
      icon: <RefreshIcon />,
      label: t('resend_invite'),
      hidden: () => user?.status === 'active',
    },
    {
      // TODO - we dont have this API
      onClick: () => {},
      icon: <EnvelopeIcon />,
      label: t('send_password_reset'),
    },
    {
      icon: <DeleteDustbin />,
      label: t('remove'),
      onClick: () => {
        setUserDeleting(true);
      },
      hidden: () => user && user.status !== 'active',
      color: 'reds.500',
    },
  ];

  return (
    <>
      {userDeleting ? (
        <ConfirmationDialog
          title={t('user_delete')}
          description={
            <Trans i18nKey="user_delete_confirmation_description" values={{ user: user?.email }} />
          }
          confirmColor="error"
          onClose={() => setUserDeleting(false)}
          onConfirm={handleConfirmDelete}
        />
      ) : null}
      <Stack gap={4}>
        <BackButton to={linkHelper.newDesign.settings.users.getLink()} />
        <Stack gap={6}>
          <Typography variant="h2" color="greys.900">
            {t('user_profile')}
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gap: 6,
              gridTemplateColumns: {
                sm: '1fr',
                lg: '380px 1fr',
              },
            }}
          >
            <Card
              sx={{
                height: 'fit-content',
              }}
            >
              <Stack gap={5} sx={{ padding: 4 }}>
                <Gravatar
                  isLoading={isLoading}
                  size="xxl"
                  name={`${data?.result.firstname} ${data?.result.lastname}`}
                  email={data?.result.email}
                />
                <Box>
                  <Typography variant="h6" color="greys.900" fontWeight="700">
                    {isLoading ? <Skeleton height="100%" width="200px" /> : data?.result.email}
                  </Typography>
                  <Typography variant="caption" color="greys.400" fontWeight="400">
                    {isLoading ? (
                      <Skeleton height="24px" width="200px" />
                    ) : (
                      t('invited_on', {
                        date: formatLocaleDate({
                          locale: i18n.language,
                          value: data?.result.created,
                          includeTime: false,
                          options: {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          },
                        }),
                      })
                    )}
                  </Typography>
                </Box>
                <Stack gap={4} divider={<Divider />}>
                  {actions
                    .filter(action => !action.hidden || action.hidden() !== true)
                    .map(action => {
                      if (isLoading) {
                        return (
                          <Skeleton key={action.label.toString()} height="20px" width="100%" />
                        );
                      }
                      return (
                        <Button
                          onClick={action.onClick}
                          key={action.label.toString()}
                          variant="text"
                          sx={{ justifyContent: 'start' }}
                        >
                          <Stack direction="row" gap={2} alignItems="center">
                            <IconWrapper size="s" color={action.color ?? 'greys.400'}>
                              {action.icon}
                            </IconWrapper>
                            <Typography variant="body2" color={action.color ?? 'greys.900'}>
                              {action.label}
                            </Typography>
                          </Stack>
                        </Button>
                      );
                    })}
                </Stack>
              </Stack>
            </Card>
            <Stack gap={6}>
              <UserPersonalInfo user={user} isLoading={isLoading} />
              {/* TODO - need a way to determine account user vs site user in the route */}
              <UserTwoFactorAuth isLoading={isLoading} user={user} />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
